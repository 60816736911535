import React from "react"
import {  graphql } from "gatsby"

import Layout from "../components/layout"
import CoverLayout from "../components/CoverLayout"
import { HeadComponent } from "../components/HeadComponent"

import { main } from "./index.module.scss"

const MentionsLegales = ({ data }) => {

  const coverImage = {
    image: data.image,
    alt: "© - Milomon - https://www.instagram.com/milomon/",
  }
  
  return (
    <Layout>
      <HeadComponent title="Kadata - Mentions Légales" />
      <CoverLayout coverImage={coverImage}>
        <div className={main}>
          <h3>Mentions Légales</h3>
          <p>
            Site édité par Kadata Sas - 86, rue Voltaire 93100 Montreuil
            <br />
            Hébergeur : Google Ireland Limited
          </p>
          <p>
            Photographies avec l'aimable autorisation de © Milomon -
            https://www.instagram.com/milomon/
            <br />
            et © Chloé Bonnard - https://www.instagram.com/chloebonnardphoto/
          </p>
        </div>
      </CoverLayout>
    </Layout>
  )
}



export const pageQuery = graphql`{
  image: file(relativePath: {eq: "milomon/BPCedCGA0vF.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 480
        tracedSVGOptions: {background: "#fff", color: "#007FE6"}
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
}
`

export default MentionsLegales
